<template>
  <app-module-view>
    <template slot="body">
      <div>
        <div class="m-b-10">
          <div class="row">
            <div class="col-6">
            </div>
            <div class="text-right col-6">
              <button
                type="button"
                class="btn btn-success"
                @click="save"
              >
                <i class="fa fa-save "></i> {{ $t('eshopSubscription.save') }}
              </button>
              <app-button-delete v-if="subscription.id" @deleteRecord="deleteRecord"></app-button-delete>
              <app-button-close route-name="eshopSubscription_list"></app-button-close>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8">
            <div class="card card-body">
              <div class="row" v-if="subscription.id">
                <div class="col-lg-12">
                  <app-input
                    v-model="subscription.code"
                    id="subscription_code"
                    :label="$t('eshopSubscription.code')"
                    readonly
                  >
                  </app-input>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <app-select
                    v-model="subscription.type"
                    :options="subscriptionTypeValues"
                    @blur="$v.subscription.type.$touch()"
                    :error="$v.subscription.type.$error"
                    :label="$t('eshopSubscription.type')"
                    track-by="id"
                    id="subscription_type"
                  >
                  </app-select>
                </div>
              </div>
              <div class="row" v-if="subscription.type === 'interval_in_days'">
                <div class="col-lg-12">
                  <app-input
                    v-model="subscription.activeDays"
                    id="subscription_activeDays"
                    :label="$t('eshopSubscription.activeDays')"
                  >
                  </app-input>
                </div>
              </div>
              <div class="row" v-if="subscription.type === 'date_interval'">
                <div class="col-lg-12">
                  <app-datepicker
                    v-model="subscription.activeSince"
                    id="subscription_activeSince"
                    :label="$t('eshopSubscription.activeSince')"
                  >
                  </app-datepicker>
                </div>
              </div>
              <div class="row" v-if="subscription.type === 'date_interval'">
                <div class="col-lg-12">
                  <app-datepicker
                    v-model="subscription.activeUntil"
                    id="subscription_activeUntil"
                    :label="$t('eshopSubscription.activeUntil')"
                  >
                  </app-datepicker>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <app-input
                    v-model="subscription.accounts"
                    id="subscription_accounts"
                    :label="$t('eshopSubscription.accounts')"
                  >
                  </app-input>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <label>{{ $t('eshopSubscription.digitalServices') }}</label><br>
                  <app-multi-select
                    v-model="subscription.digitalServices"
                    :options="digitalServices"
                    label="name"
                    track-by="id"
                    id="subscription_digitalServices"
                  >
                  </app-multi-select>
                </div>
              </div>
              <div class="row">
                <div class="px-3">
                  <app-checkbox
                    v-model="subscription.setting.marketing"
                    id="subscription_setting_marketing"
                    :label="$t('eshopSubscription.setting.marketing')"
                  >
                  </app-checkbox>
                </div>
              </div>
              <div class="row">
                <div class="px-3">
                  <app-checkbox
                    v-model="subscription.setting.admin"
                    id="subscription_setting_admin"
                    :label="$t('eshopSubscription.setting.admin')"
                  >
                  </app-checkbox>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <app-datepicker
                    v-model="subscription.expirationAt"
                    id="subscription_expirationAt"
                    :label="$t('eshopSubscription.expiration_at')"
                  >
                  </app-datepicker>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <label>{{ $t('eshopSubscription.admin') }}</label>
                  <app-multi-select
                    v-model="subscription.adminExpanded"
                    :options="feUsers"
                    :closeOnSelect="true"
                    :preserveSearch="true"
                    label="email"
                    trackBy="id"
                    :internalSearch="true"
                    :optionsLimit="300"
                    :limit="10"
                    :maxHeight="600"
                    :showNoResults="false"
                    @search-change="findFeUser"
                    selectLabel=""
                    id="subscription_admin"
                    :multiple="false"
                  >
                  </app-multi-select>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="card card-body" v-if="subscription.id">
              <h3>{{ $t('eshopSubscription.systemInfo') }}</h3>
              <table class="table table-striped table-bordered">
                <tbody>
                <tr>
                  <td>{{ $t('eshopSubscription.id') }}</td>
                  <td>{{ subscription.id }}</td>
                </tr>
                <tr>
                  <td>{{ $t('eshopSubscription.created_at') }}</td>
                  <td>{{ subscription.createdAt | prettyDateTime }}</td>
                </tr>
                <tr>
                  <td>{{ $t('eshopSubscription.modified_at') }}</td>
                  <td>{{ subscription.modifiedAt | prettyDateTime }}</td>
                </tr>
                </tbody>
              </table>
            </div>

            <div class="card card-body" v-if="!subscription.id">
              <h3>{{ $t('eshopSubscription.countOfCodes') }}</h3>
              <div class="row">
                <div class="col-lg-12">
                  <app-input
                    v-model="countOfCodes"
                    id="countOfCodes"
                  >
                  </app-input>
                </div>
              </div>
              <h3 v-if="countOfCodes > 1 && codes.length">{{ $t('eshopSubscription.list') }}</h3>
              <table class="table table-striped table-bordered" v-if="countOfCodes > 1 && codes.length">
                <tbody>
                <tr v-for="(code, index) in codes" :key="`item-${index}`">
                  <td>{{ code }}</td>
                </tr>
                </tbody>
              </table>
            </div>

            <div class="card card-body" v-if="subscribers.length">
              <h3>{{ $t('eshopSubscription.subscribers') }}</h3>
              <table class="table table-striped table-bordered">
                <thead>
                <tr>
                  <td>{{ $t('eshopSubscription.created_at') }}</td>
                  <td>{{ $t('eshopSubscription.email') }}</td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(subscriber, index) in subscribers" :key="`item-${index}`">
                  <td>{{ subscriber.createdAt | prettyDateTime }}</td>
                  <td style="cursor: pointer; color: #1e88e5;" v-on:click="goToSubscribers(subscriber)">
                    {{ subscriber.email }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../ModuleView'
import SubscriptionModel from '../../model/eshop/Subscription'
import Input from '../form/inputs/Input'
import Select from '../form/select/Select'
import Checkbox from '../form/Checkbox'
import Datepicker from '../form/Datepicker'
import ButtonDelete from '../shared/ButtonDelete'
import ButtonClose from '../shared/ButtonClose'
import NotifyService from '../../services/NotifyService'
import SubscriptionTypeMixin, { SUBSCRIPTION_TYPE_DAYS } from '../mixins/valueObject/eshop/SubscriptionTypeMixin'
import MultiSelect from '../form/select/MultiSelect'
import { required, minLength } from 'vuelidate/lib/validators'
import SubscriberFilter from '../../model/eshop/SubscriberFilter'

export default {
  name: 'SubscriptionNew',
  mixins: [
    SubscriptionTypeMixin
  ],
  data () {
    return {
      subscription: this._.cloneDeep(SubscriptionModel),
      subscribers: [],
      countOfCodes: 1,
      codes: []
    }
  },
  validations: {
    subscription: {
      type: {
        required,
        minLength: minLength(5)
      }
    }
  },
  computed: {
    digitalServices () {
      return this.$store.getters['eshopDigitalService/all']
    },
    feUsers () {
      return this.$store.getters['feUser/list']
    }
  },
  components: {
    appModuleView: ModuleView,
    appInput: Input,
    appSelect: Select,
    appCheckbox: Checkbox,
    appDatepicker: Datepicker,
    appButtonDelete: ButtonDelete,
    appButtonClose: ButtonClose,
    appMultiSelect: MultiSelect
  },
  methods: {
    initFields () {
      this.subscription.digitalServices = this.subscription.digitalServices.map(digitalServiceId => {
        return this.$store.getters['eshopDigitalService/digitalServiceById'](digitalServiceId)
      })
      this.subscribers = []
      this.subscription.subscribers.forEach(subscriber => {
        this.loadSubscriber(subscriber)
      })
    },
    prepareSubscriptionRequest (subscription) {
      delete subscription.modifiedBy
      delete subscription.modifiedAt
      delete subscription.createdBy
      delete subscription.createdAt
      subscription.digitalServices = subscription.digitalServices.map(digitalService => {
        // Map id only when is necessary (for first code)
        return (digitalService.id) ? digitalService.id : digitalService
      })
      if (!subscription.activeSince) {
        delete subscription.activeSince
      }
      if (!subscription.activeUntil) {
        delete subscription.activeUntil
      }
      if (!subscription.expirationAt) {
        delete subscription.expirationAt
      }
      if (subscription.adminExpanded && subscription.adminExpanded.id) {
        subscription.admin = subscription.adminExpanded.id
      }
      delete subscription.adminExpanded

      if (subscription.type === SUBSCRIPTION_TYPE_DAYS) {
        subscription.activeSince = null
        subscription.activeUntil = null
      }
      return subscription
    },
    async save () {
      this.$v.$touch()

      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('eshopSubscription.error.required_fields'))
        return
      }

      // Validation
      if (this.subscription.type === 'date_interval' &&
        (this.subscription.activeSince === '' ||
          this.subscription.activeUntil === '')) {
        NotifyService.setErrorMessage(this.$t('eshopSubscription.error.required_date_interval'))
        return
      }

      // Create subscription
      for (let i = 1; i <= this.countOfCodes; i++) {
        this.$store.dispatch('eshopSubscription/create', this.prepareSubscriptionRequest(this.subscription))
          .then(() => {
            const subscription = this.$store.getters['eshopSubscription/detail']
            if (this.$store.getters['eshopSubscription/error'] === null) {
              NotifyService.setSuccessMessage(this.$t('eshopSubscription.message.created_record'))
              if (this.countOfCodes > 1) {
                this.codes.push(subscription.code)
              } else {
                this.$router.push('/shop/subscription/subscription/' + subscription.id + '/edit')
              }
            } else {
              NotifyService.setErrorMessage(this.$store.getters['eshopSubscription/error'])
            }
          })
          .catch(error => console.log(error))
      }
    },
    deleteRecord () {
      this.$store.dispatch('eshopSubscription/deleteRecord', this.subscription)
        .then(() => {
          if (this.$store.getters['eshopSubscription/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('eshopSubscription.message.deleted_record'))
            this.$router.push('/shop/subscription/subscription')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['eshopSubscription/error'])
          }
        })
        .catch(error => console.log(error))
    },
    findFeUser (query) {
      this.$store.dispatch('feUser/fetch', query)
    },
    loadSubscriber (subscriber) {
      this.$store.dispatch('feUser/fetchOneById', subscriber.feUser).then(response => {
        if (response.data) {
          subscriber.email = response.data.email
          this.subscribers.push(subscriber)
        }
      })
    },
    goToSubscribers (subscriber) {
      const filter = this._.cloneDeep(SubscriberFilter)
      filter.userEmail = subscriber.userEmail

      this.$store.commit('eshopSubscriber/setPage', 1)
      this.$store.commit('eshopSubscriber/setFilter', filter)
      this.$router.push('/shop/subscriber')
    }
  },
  created () {
    this.$store.dispatch('eshopDigitalService/fetchAll', null, { root: true }).then(() => {
      this.initFields()
    })
  }
}
</script>

<style lang="scss" scoped>

</style>
